<template>
  <div class="row">
    <div class="col-6 mb-3 text-left"></div>
    <div class="col-6 mb-3 text-left">
      <form @submit.prevent="search">
        <div class="form-group">
          <label for="">
            Search by Invoice ID
          </label>
          <div class="input-group mb-3">
            <input
              type="text"
              v-model="invoiceId"
              class="form-control"
              placeholder="Invoice ID xxxxxxx"
            />
            <div class="input-group-append">
              <input type="submit" value="Search" class="btn btn-success" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SearchInvoiceComponent',
    data() {
      return {
        invoiceId: ''
      }
    },
    computed: {
      query() {
        return this.$route.query
      }
    },
    watch: {
      query(val) {
        if (val.invoiceId) this.invoiceId = val.invoiceId
      }
    },
    methods: {
      search() {
        this.$emit('search', this.invoiceId)
      }
    },
    mounted() {
      const { invoiceId } = this.query
      if (invoiceId) this.invoiceId = invoiceId
    }
  }
</script>
<style lang="scss" scoped></style>
