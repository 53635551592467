<template>
  <div class="col-12 ">
    <div class="row">
      <div class="col-lg-3 col-md-5 col-sm-6 col-6">
        <router-link to="/transaction?status=unpaid#">
          <CardSummaryItemComponent
            :label="`Waiting For Payment`"
            :label-time="``"
            :value="waitingForPayment"
            :bg-color="`bg-success`"
          />
        </router-link>
      </div>
      <div class="col-lg-3 col-md-5 col-sm-6 col-6">
        <router-link to="/transaction?status=pending#">
          <CardSummaryItemComponent
            :label="`Pending Approval`"
            :label-time="``"
            :value="pendingApproval"
            :bg-color="`bg-danger`"
          />
        </router-link>
      </div>
      <div class="col-lg-3 col-md-5 col-sm-6 col-6">
        <router-link to="/transaction?status=confirmed#">
          <CardSummaryItemComponent
            :label="`On Process`"
            :label-time="``"
            :value="process"
            :bg-color="`bg-warning`"
          />
        </router-link>
      </div>
      <div class="col-lg-3 col-md-5 col-sm-6 col-6">
        <router-link to="/transaction?status=completed#">
          <CardSummaryItemComponent
            :label="`Completed Orders`"
            :label-time="``"
            :value="complete"
            :bg-color="`bg-primary`"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import CardSummaryItemComponent from '@/views/dashboard/component/CardSummaryItemComponent'
import OrderServices from '@/service/OrderServices'

export default {
  name: 'DashboardSummaryComponent',
  components: { CardSummaryItemComponent },
  data () {
    return {
      waitingForPayment: 0,
      pendingApproval: 0,
      process: 0,
      complete: 0
    }
  },
  methods: {
    async getOrderByStatus () {
      try {
        const res = await OrderServices.count()
        if (res.data) {
          this.waitingForPayment = res.data.unpaid + res.data.inpayment
          this.pendingApproval = res.data.pending
          this.process = res.data.confirmed
          this.complete = res.data.completed
          this.$emit('total', {
            totalPending: this.pendingApproval,
            totalProcess: this.process
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    this.getOrderByStatus()
  }
}
</script>
<style lang="scss" scoped>
  a:hover{
    text-decoration:none;
  }
</style>
