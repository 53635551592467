<template>
  <div>
    <div class="row">
      <DashboardSummaryComponent @total="totalOrder = $event" />
    </div>
    <hr class="hr" />
    <div class="container-neworders">
      <SearchInvoiceComponent @search="doSearch($event)" />
      <DashboardNewOrderComponent ref="orderComponent" :total="totalOrder" />
    </div>
  </div>
</template>
<script>
  import DashboardSummaryComponent from '@/views/dashboard/component/DashboardSummaryComponent'
  import DashboardNewOrderComponent from '@/views/dashboard/component/DashboardNewOrderComponent'
  import SearchInvoiceComponent from '@/views/dashboard/component/SearchInvoiceInputComponent'
  export default {
    name: 'Dashboard',
    components: {
      SearchInvoiceComponent,
      DashboardNewOrderComponent,
      DashboardSummaryComponent
    },
    data() {
      return {
        totalOrder: null
      }
    },
    methods: {
      doSearch(e) {
        this.$refs.orderComponent.doSearch(e)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .hr {
    border-top: 1px solid #eee;
  }
  .container-neworders {
    margin-top: 40px;
  }
</style>
