<template>
  <div
    :class="[ bgColor ? bgColor : '']"
    class="card">
    <div class="card-body">
      <span class="time">
        {{ labelTime }}
      </span>
      <div class="label">
        {{ label }}
      </div>
      <div class="value">
        {{ value}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardSummaryItemComponent',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelTime: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    bgColor: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  align-items: flex-start;
  color: white;
  text-align: left;
  .time {
    opacity: .8;
  }
  .label {
    font-size: 1.2rem;
  }
  .value {
    font-weight: bold;
    font-size: 2rem
  }
}
</style>
