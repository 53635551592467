<template>
  <div class="col-12 text-left">
    <h4 class="dashboard-label">{{ dashboardLabel }} ({{ totalData }} of {{ totalData }} Orders)</h4>
    <DashboardNotificationComponent :is-show="isShow" :message="message" @close="closeNotif" />
    <NewOrderItemComponent v-for="val in data" :key="val.id" @confirm="getNewData" @complete="getNewData" :data="val" />
    <div v-if="!isLoading && !data.length">
      <div class="container-empty">
        There are no results of orders currently
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import NewOrderItemComponent from '@/views/dashboard/component/NewOrderItemComponent'
  import OrderServices from '@/service/OrderServices'
  import DashboardNotificationComponent from '@/views/dashboard/component/DashboardNotificationComponent'

  export default {
    name: 'DashboardNewOrderComponent',
    components: { DashboardNotificationComponent, NewOrderItemComponent },
    props: {
      total: {
        type: Object,
        default: () => {
          return {
            totalPending: 0,
            totalProcess: 0
          }
        }
      }
    },
    data() {
      return {
        isLoading: true,
        data: [],
        limit: 10,
        keyword: '',
        isShow: false,
        message: ''
      }
    },
    computed: {
      ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator']),
      offset() {
        return this.limit === 3 ? 0 : this.data.length * this.limit
      },
      query() {
        return this.$route.query
      },
      totalShow() {
        if (this.total && (this.isAdmin || this.isOperator)) return this.total.totalPending
        if (this.total && this.isMerchant) return this.total.totalProcess
        return 0
      },
      dashboardLabel() {
        if (this.total && (this.isAdmin || this.isOperator)) {
          return 'Waiting For Confirmation'
        } else {
          return 'On Process Orders'
        }
      },
      totalData() {
        return !this.data.length ? 0 : this.data.length
      }
    },
    watch: {
      query() {
        this.getNewData()
      }
    },
    methods: {
      doSearch(e) {
        this.keyword = e
        this.data = []
        this.getData()
      },
      closeNotif() {
        this.isShow = false
      },
      getNewData(e) {
        this.keyword = ''
        if (e) {
          this.message = e.message
          this.isShow = true
        }
        this.data = []
        this.getData()
      },
      async getData() {
        const filter = {
          include: [
            {
              relation: 'items',
              scope: {
                order: 'createdTime ASC',
                include: [
                  {
                    relation: 'product'
                  }
                ]
              }
            },
            {
              relation: 'payments',
              scope: {
                order: 'createdTime DESC'
              }
            },
            {
              relation: 'user'
            },
            {
              relation: 'merchant'
            }
          ],
          where: {
            status: 'pending'
          },
          order: 'createdTime ASC',
          limit: this.limit,
          offset: this.offset
        }
        if (this.isAdmin || this.isOperator) {
          filter.where = {
            or: [
              { status: 'pending', serviceCode: 'STO' },
              { status: 'pending', serviceCode: 'SDA' }
            ]
          }
        }

        if (this.isMerchant) {
          filter.where.status = 'confirmed'
        }

        if (this.keyword) {
          filter.where = {
            ...filter.where,
            ...{
              invoiceNumber: this.keyword
            }
          }
        }
        this.isLoading = true
        try {
          const res = await OrderServices.getAll(`filter=${JSON.stringify(filter)}`)
          if (res) this.data = res.data
        } catch (e) {
          console.log('get order error', e, e.response)
        }
        this.isLoading = false
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>
<style lang="scss" scoped>
  .dashboard-label {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  .container-empty {
    flex: 1;
    font-size: 1.5em;
    color: #999;
    margin: 50px auto;
    text-align: center;
  }
</style>
